import { BoardCommonControllerService } from '@/__generated__/CommonApi';
import { ChildParticipationControllerService } from '@/__generated__/FrontApi';
import CourtesyImg1 from '@/assets/img/child-participation/courtesy_education01.png';
import CourtesyImg1M from '@/assets/img/child-participation/courtesy_education01@2x.png';
import CourtesyImg2 from '@/assets/img/child-participation/courtesy_education02.png';
import CourtesyImg2M from '@/assets/img/child-participation/courtesy_education02@2x.png';
import CourtesyImg3 from '@/assets/img/child-participation/courtesy_education03.png';
import CourtesyImg3M from '@/assets/img/child-participation/courtesy_education03@2x.png';
import CourtesyImg4 from '@/assets/img/child-participation/courtesy_education04.png';
import CourtesyImg4M from '@/assets/img/child-participation/courtesy_education04@2x.png';
import TopbgM from '@/assets/img/child-participation/village_bgM@2x.png';
import Bn from '@/assets/img/child-participation/village_bn.png';
import BnM from '@/assets/img/child-participation/village_bnM@2x.png';
import CardImg1 from '@/assets/img/child-participation/village_slide_img01.png';
import CardImg2 from '@/assets/img/child-participation/village_slide_img02.png';
import CardImg3 from '@/assets/img/child-participation/village_slide_img03.png';
import CardImg4 from '@/assets/img/child-participation/village_slide_img04.png';
import CardImg5 from '@/assets/img/child-participation/village_slide_img05.png';
import CardImg6 from '@/assets/img/child-participation/village_slide_img06.png';
import VillageLogo from '@/assets/img/child-participation/village_title_logo.png';
import VillageLogoM from '@/assets/img/child-participation/village_title_logo@2x.png';
import icoMore from '@/assets/img/icons/arrow_sky@2x.png';
import icoMoreHover from '@/assets/img/icons/arrow_white@2x.png';
import Topbg from '@/assets/img/tmp/child-participation/village_bg.png';
import { Article } from '@/components/Article/types';
import Button from '@/components/Button';
import ThumbnailCardSample from '@/components/Card/ThumbnailCardSample';
import VillageCard from '@/components/Card/VillageCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import Image from '@/components/Image';
import VillageLayerPopup from '@/components/Layer/VillageLayerPopup';
import PageComponent from '@/components/PageComponent';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { BOARD_KIND_VILLAGE } from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { navigate, PageProps } from 'gatsby';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const topData = [
  {
    id: 1,
    videoSrc: `cFJD8EwZJQU`,
  },
  {
    id: 2,
    pcImage: CardImg1,
    mobileImage: CardImg1,
    dec: `<p>유니세프의 활동과 <del>아동권리 중요성</del>에 대해 <br/>공감하고 스스로 성찰할 수 있는 교육 공간</p>`,
  },
  {
    id: 3,
    pcImage: CardImg2,
    mobileImage: CardImg2,
    dec: `<p><del>세계시민</del>으로 아동권리를<br/>바로 알고 지킬 수 있는 공간</p>`,
  },
  {
    id: 4,
    pcImage: CardImg3,
    mobileImage: CardImg3,
    dec: `<p>어린이가 자유롭고 창의적으로 생각하고<br/><del>마음을 나눌</del> 수 있는 공간</p>`,
  },
  {
    id: 5,
    pcImage: CardImg4,
    mobileImage: CardImg4,
    dec: `<p>아동권리를 옹호하는 대표 기관으로<br/><del>유니세프를 떠올릴</del> 수 있는 공간</p>`,
  },
  {
    id: 6,
    pcImage: CardImg5,
    mobileImage: CardImg5,
    dec: `<p>기부와 나눔에 대해 익숙해지고<br/><del>기부문화를 확산</del>할 수 있는 공간</p>`,
  },
];
const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `아동권리사업팀`,
    tel: `02-724-8598`,
    email: `village@unicef.or.kr`,
  },
];

const Bg = styled.div`
  background: url(${Topbg}) center top no-repeat;
  background-size: cover;
  width: 100%;
  height: 77.2%;
  position: absolute;
  top: -92px;
  max-height: 818px;
`;

const Content = styled.div`
  position: relative;

  ${Container} {
    padding-top: 92px;
    padding-bottom: 84px;
    margin-bottom: 15px;
    position: relative;
  }

  .swiper-container {
    margin-left: -16px;
    margin-right: -16px;
    overflow: hidden;

    .swiper-slide {
      width: 50%;
      padding: 0 16px;
      margin: 0;

      .img-box {
        padding-top: 56.62%;
      }

      .dec {
        margin-top: 60px;
        font-size: 26px;
        line-height: 42px;

        span {
          display: block;
          margin-top: 16px;
          font-size: 16px;
          font-weight: 400;
          line-height: 2;
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    ${Bg} {
      background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: -178px;
      z-index: -1;
    }
    ${Container} {
      padding-top: 73px;
      padding-bottom: 30px;
      margin-bottom: 66px;
      .logo {
        top: 78px;
        max-width: 208px;
      }
    }

    .swiper-container {
      .swiper-slide {
        width: 100%;
        .dec {
          margin-top: 24px;
          font-size: 16px;
        }
      }
    }
  }

  ${breakpoint(`mobile`)} {
    ${Bg} {
      height: 100%;
      top: -146px;
      background: url(${TopbgM}) center top no-repeat;
      background-size: cover;
    }
    .swiper-container {
      .swiper-slide {
        .dec {
          line-height: 26.25px;
        }
      }
    }
  }
`;
const Box = styled.div`
  border: 1px solid #1cabe2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 40px;
  margin-top: 64px;

  ${Tit} {
    width: calc(100% - 256px);
  }
  ${Button} {
    width: 100%;
    max-width: 256px;
  }

  ${breakpoint(`tablet`)} {
    padding: 24px;
    margin-top: 48px;
    justify-content: center;
    flex-wrap: wrap;

    ${Tit} {
      width: 100%;
      margin-bottom: 16px;
      text-align: center;
    }
    ${Button} {
      max-width: 148px;
    }
  }
`;

const SectionTop = styled(Section)`
  &:first-of-type {
    padding: 0;
    padding-top: 110px;
  }
  ${SectionHeader} {
    p {
      margin-top: 52px;
    }
  }
  ${breakpoint(`tablet`)} {
    h2 {
      ${Tit} {
        font-size: 25px;
      }
    }
    ${SectionHeader} {
      padding-bottom: 54px;
      p {
        margin-top: 46.5px;
        font-size: 15px;
        letter-spacing: -1.6px;
        line-height: 28px;
      }
    }
    &:first-of-type {
      padding: 0;
      padding-top: 81px !important;
    }
  }
`;

const DlContainer = styled.div`
  .mt-47 {
    margin-top: 47px;
  }
  & .tip:first-of-type {
    margin-top: 10px;
  }
  .tip {
    color: #afb0b3;
    font-size: 16px;
    padding-left: 20px;
    position: relative;
    &.blue {
      color: #3162af;
    }
    ${breakpoint(640)} {
      font-size: 14px;
    }
    ::before {
      content: '※ ';
      position: absolute;
      left: 0;
      top: 0;
      font-size: 16px;
      color: #b0b0b0;
    }
  }

  dl {
    display: flex;
    align-items: flex-start;
    padding: 32px 0;
    border-bottom: 1px solid #f0f1f2;
    &:first-child {
      padding-top: 0;
    }

    dt {
      width: 34%;

      ${Tit} {
        position: relative;
        padding-left: 25px;

        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background: #1cabe2;
          position: absolute;
          left: 0;
          top: 15px;
        }

        strong {
          color: #1cabe2;
        }
      }
    }
    dd {
      width: 66%;
      line-height: 28px;

      .blit-list {
        li {
          &::before {
            background: #2d2926;
          }
        }
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 0px;
      border-top: none;
      border-left: none;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #2d2926;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    th,
    td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #e5e6e8;
      color: #2d2926;
    }

    th {
      background-color: #edf7fa;
      text-align: center;
      font-weight: 400;
      width: 31.116%;
      border-right: none;
    }

    th:nth-child(2) {
      border-right: none;
    }

    td {
      color: #666666;
      padding-left: 39px;
      border-right: none;
      border-left: 1px solid #e5e6e8;
    }

    .schedule_table thead,
    .schedule_table tbody,
    .schedule_table thead > tr,
    .schedule_table tbody > tr {
      width: 100%;
    }

    .schedule_table thead th,
    .schedule_table thead td {
      background-color: #edf7fa;
      color: #2d2926;
    }

    .schedule_table th,
    .schedule_table td {
      width: 10%;
      height: 77px;
    }

    .schedule_table td {
      text-align: center;
      padding-left: 10px;
    }

    .schedule_table td:first-child {
      border-right: 1px solid #e5e6e8;
      border-left: 0;
    }
  }

  .cnt-list {
    & > li {
      margin: 0 0 16px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
    .btn-download {
      margin-top: 8px;
      width: 100%;
      max-width: 352px;
    }
  }
  .btn-flex {
    display: flex;
    max-width: 768px;
    width: 100%;
    margin-top: 8px;

    & > li {
      width: 50%;

      button {
        width: 100%;
      }
    }
  }
  .pc-show {
    display: block;
  }
  .m-show {
    display: none;
  }
  .m-show-inline {
    display: none;
  }

  ${breakpoint(`tablet`)} {
    .bbn {
      border-bottom: none;
    }
    dl {
      td {
        padding-left: 16px;
        border-left: 2px solid #e5e6e8;
      }

      th,
      td {
        height: 54px;
        border-bottom: 2px solid #e5e6e8 !important;
      }
      padding: 34px 0;
      dt {
        ${Tit} {
          &::before {
            top: 11px;
          }
        }
      }
      /* dd {
        padding-left: 25px;
        line-height: 24px;
      } */
      table {
        &::before {
          height: 2px;
        }
      }
      .schedule_table th,
      .schedule_table td {
        height: 58px;
      }
      .schedule_table thead th,
      .schedule_table thead td {
        background-color: #edf7fa;
      }
      .schedule_table thead td {
        border-left: 2px solid #e5e6e8;
      }
    }
  }
  ${breakpoint(`mobile`)} {
    .pc-show {
      display: none;
    }
    .m-show {
      display: block;
    }
    .m-show-inline {
      display: inline;
    }
    .pb-92 {
      padding-bottom: 92px;
    }
    dl {
      flex-wrap: wrap;

      dt,
      dd {
        width: 100%;
      }

      dd {
        margin-top: 16px;
      }

      dd p,
      dd ul {
        /* margin-top: 16px; */
        padding-left: 25px;
      }
    }

    .cnt-list {
      .btn-download {
        // margin-left: -20px;
        max-width: 100%;
        width: 100%;
      }
    }

    .btn-flex {
      flex-wrap: wrap;
      max-width: 100%;

      & > li {
        width: 100%;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        ${Button} {
          // margin-left: -20px;
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
`;
const SectionHow = styled(Section)`
  /* padding-bottom: 96px !important; */

  .mb-34 {
    margin-bottom: 34px;
  }

  .logo {
    width: 100%;
    height: 100%;
    max-width: 505px;
    max-height: 81px;
    margin-bottom: 118px;
  }
  ${DlContainer} {
    & :nth-child(3) {
      padding-bottom: 0px;
    }
    padding-bottom: 120px;
  }

  ${breakpoint(`tablet`)} {
    .mb-34 {
      margin-bottom: 0px;
    }
    .logo {
      max-width: 302px;
      margin-bottom: 63px;
    }
  }

  ${breakpoint(`mobile`)} {
    ${DlContainer} {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
`;

const SectionBn = styled(Section)`
  margin: 120px auto;
  button {
    display: block;
    width: 100%;
  }
  .bn {
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;

    img {
      width: 100%;
    }
  }
  ${breakpoint(`tablet`)} {
    margin: 65px auto;
    header {
      br {
        display: none;
      }
    }
  }
`;

const SectionBgc = styled.div`
  background-color: #f8fcfe;
  padding-top: 140px;
  ${breakpoint(`tablet`)} {
    padding-top: 96px;
  }
`;

const SectionContact = styled(Section)`
  /* padding-top: 96px !important; */
`;

const SectionCourtesy = styled(Section)`
  ${Container} {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    div {
      display: flex;
      width: 50%;
      & :nth-child(1),
      & :nth-child(2) {
        padding-bottom: 50px;
      }
      & :nth-child(3),
      & :nth-child(4) {
        padding-top: 50px;
      }
      ${Image} {
        max-width: 150px;
        min-width: 150px;
        height: 150px;
      }
      & :nth-child(odd) {
        padding-right: 24px;
      }
      & :nth-child(even) {
        padding-left: 24px;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    padding: 120px 0 0 0 !important;
    ${Container} {
      .blue {
        margin-bottom: 16px;
      }
      div {
        width: 50%;
        display: block;
        text-align: center;
        & :nth-child(1),
        & :nth-child(2) {
          padding-bottom: 48px;
        }

        & :nth-child(3),
        & :nth-child(4) {
          padding-top: 0px;
          padding-bottom: 48px;
        }
        ${Image} {
          max-width: 93.75px;
          min-width: 93.75px;
          height: 93.75px;
          margin-bottom: 30px;
        }
        & :nth-child(odd) {
          padding-right: 12px;
        }
        & :nth-child(even) {
          padding-left: 12px;
        }
        & :last-child {
          padding-bottom: 0;
        }
        :last-child {
          margin-bottom: 95px;
        }
      }
    }
  }
  ${breakpoint(`mobile`)} {
    ${Container} {
      div {
        ${Image} {
          margin-bottom: 30px;
        }
        text-align: center;
      }
    }
  }
`;
const CourtesyText = styled(Section)`
  margin-left: 46px;
  margin-right: 30px;
  .blue {
    color: #1cabe2;
    margin-bottom: 25px;
  }
  p {
    line-height: 28px;
  }
  ${breakpoint(`mobile`)} {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  ${Button} {
    .ico {
      padding-right: 32px;
      display: inline-block;
      width: auto;
      text-align: center;
      position: relative;
      left: 16px;
      &::before {
        width: 32px;
        height: 32px;
        background-image: url(${icoMore});
      }
    }
    &:hover {
      .ico {
        &::before {
          background-image: url(${icoMoreHover});
        }
      }
    }
  }
`;

// 한 페이지당 보여줄 아이템 수
const pagePerCount = 8;

const Village: React.FC<PageProps> = ({ location }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [PopupTitle, setPopupTitle] = useState(``);
  const userStore = useUserStore();
  const popupStore = usePopupStore();

  const [typeApply, setTypeApply] = useState(``);

  const [applyData, setApplyData] = useState([]);
  const [applyChk, setApplyChk] = useState(false);

  const open = () => setPopupOpen(true);
  const close = () => setPopupOpen(false);

  // 현재 페이지
  const [page, setPage] = useState(1);
  // 전체 글 수 (현재 조건기준)
  const [total, setTotal] = useState(0);
  const [newsLists, setNewsLists] = useState<Article[]>([]);

  const hasMore = useMemo(() => total > pagePerCount * page, [page, total]);

  const [userCount, setUserCount] = useState(0);
  const [limitCount, setLimitCount] = useState(5);

  // 최근 소식
  const loadItem = useCallback(
    () =>
      BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [BOARD_KIND_VILLAGE],
        pagePerCount,
        page,
      })
        .then(({ data, totalCount }) => {
          setNewsLists((prevEvents) => [...prevEvents, ...data]);
          setTotal(totalCount);
        })
        .catch((e) => {
          console.error(e);
        }),
    [page],
  );

  // 사용자 체험관 현황 조회
  async function fetchChildApplyStatus() {
    try {
      // Call the API method from the service
      const result = await ChildParticipationControllerService.getChildApplyMyUsingGet();
      setLimitCount(result.data.limitCount);
      setUserCount(result.data.beforeTimeCount);
    } catch (error) {
      // Handle any errors here
      console.error('API Call Failed:', error);
    }
  }

  useEffect(() => {
    loadItem();
    fetchChildApplyStatus();
  }, [loadItem]);

  // 다음 페이지 로드
  const loadNextPage = () => {
    setPage(page + 1);
  };

  const handleApplyClick = () => {
    if (userCount >= limitCount) {
      popupStore.show(`신청 횟수 ${limitCount}회가 초과되었습니다.`, {
        onConfirm: close,
      });
    } else {
      open();
      setPopupTitle(`유니세프 어린이 체험관 신청`);
      setTypeApply(``);
    }
  };

  return (
    <LayoutWithTitle
      location={location}
      title="유니세프 어린이체험관"
      description="for every child, experience"
    >
      <SectionTop>
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1" />
              </Tit>
            </h2>
            <p>
              <PageComponent id="content1" />
            </p>
          </SectionHeader>
        </Container>

        <Content>
          <Bg />
          <Container>
            <VillageCard campaigns={topData} overflowHidden />
          </Container>
        </Content>
      </SectionTop>
      <SectionBgc>
        <SectionHow>
          <Container>
            <Image
              pcSrc={VillageLogo}
              mobileSrc={VillageLogoM}
              className="logo"
            />
            <DlContainer>
              <Tit size="s8">관람안내</Tit>
              <dl>
                <dt>
                  <Tit size="s4-1">
                    <strong>관람시간</strong>
                  </Tit>
                </dt>
                <dd>
                  <table>
                    <tr>
                      <th>단체 프로그램 (*예약필수)</th>
                      <td>09:30 ~ 17:00 <br/>
                       (월~금/매일 4회차 운영) </td>
                    </tr>
                    <tr>
                      <th>개인 자유관람</th>
                      <td>09:00 ~ 17:00 (상시)<br/>
                      (월~금/단체예약이 있는 경우 대기 발생)

                      </td>
                    </tr>
                  </table>
                  <p className="tip">12:00 ~ 13:00 브레이크 타임</p>
                  <p className="tip">
                    휴관일: 주말, 공휴일, 창립기념일(1/2) 및 기타 기관에서
                    지정한 휴무일
                  </p>
                </dd>
              </dl>
              <dl>
                <dt>
                  <Tit size="s4-1">
                    <strong>프로그램</strong>
                  </Tit>
                </dt>
                <dd>
                  <table>
                    <tr>
                      <th>단체 프로그램</th>
                      <td>
                        단체 대상(4인 이상 30명 이하)으로 교육 프로그램이
                        제공됩니다.
                      </td>
                    </tr>
                    <tr>
                      <th>개인 자유관람</th>
                      <td>
                        개인 자율 관람으로 도슨트/교육 프로그램이 제공되지
                        않습니다.
                      </td>
                    </tr>
                  </table>
                </dd>
              </dl>
              <dl>
                <dt>
                  <Tit size="s4-1">
                    <strong>관람료</strong>
                  </Tit>
                </dt>
                <dd>
                  <p>무료</p>
                </dd>
              </dl>
              <dl>
                <dt>
                  <Tit size="s4-1">
                    <strong>관람안내</strong>
                  </Tit>
                </dt>
                <dd>
                  <ul className="blit-list">
                    <li>
                      단체 프로그램 신청은 인터넷 예약을 통해서만 관람하실 수
                      있습니다.
                    </li>
                    <li>
                      개인 자유관람은 현장 접수를 통해 관람하실 수 있으며,
                      상황에 따라 대기가 발생할 수 있습니다.
                    </li>
                    <li>
                      체험관의 모든 공간은 금연이며, 식음료 반입이 금지됩니다.
                    </li>
                    <li>
                      전시물에 낙서하거나 손상을 입힐 수 있는 행위는 절대 삼가해
                      주세요.
                    </li>
                    <li>안내견 외의 애완동물 출입은 불가합니다.</li>
                    <li>
                      체험관 내에서는 자전거, 킥보드, 인라인스케이트,
                      스케이트보드 등을 이용할 수 없습니다.
                    </li>
                    <li>상업적 촬영은 금지되어 있습니다.</li>
                  </ul>
                </dd>
              </dl>
            </DlContainer>
            <DlContainer>
              <Tit size="s8" className="mt-47">
                단체 프로그램
              </Tit>
              <dl>
                <dt>
                  <Tit size="s4-1">
                    <strong>신청 대상</strong>
                  </Tit>
                </dt>
                <dd>
                  <p>
                    유치원(3세~7세)·초·중·고등학교 4인 이상 30명 이하 단체
                  </p>
                  <p>
                    (단체 프로그램 신청 시 신청기관의 성인 인솔자가 반드시 함께
                    해주셔야 합니다.)
                  </p>
                </dd>
              </dl>
              <dl>
                <dt>
                  <Tit size="s4-1">
                    <strong>체험활동</strong>
                  </Tit>
                </dt>
                <dd>
                  <p>유니세프 아동권리 프로그램 교육 제공</p>
                  <p style={{ marginBottom: '33px' }}>
                    (기본 50분 프로그램으로 추가 교육이 필요한 경우 담당부서로
                    연락 주세요.)
                  </p>
                  <table>
                    <tr>
                      <th>안내</th>
                      <td>유니세프가 하는 일 및 체험활동 소개</td>
                    </tr>
                    <tr>
                      <th>프로그램</th>
                      <td>
                        <ul
                          className="blit-list"
                          style={{ paddingLeft: '0px' }}
                        >
                          <li>
                            교육·보호·식수·보건·공평한 기회 및 긴급구호 체험활동
                          </li>
                          <li>토론 및 발표 활동</li>
                        </ul>
                      </td>
                    </tr>
                  </table>
                </dd>
              </dl>
              <dl>
                <dt>
                  <Tit size="s4-1">
                    <strong>운영 시간</strong>
                  </Tit>
                </dt>
                <dd>
                  <ul className="blit-list">
                    <li>
                      월~금 (휴무: 주말, 공휴일, 창립기념일(1/2) 및
                      기타 기관에서 지정한 휴무일)
                    </li>
                    <li style={{ marginBottom: '33px' }}>09:30 ~ 17:00</li>
                  </ul>
                  <table className="schedule_table">
                    <thead>
                      <tr>
                        <th>회차</th>
                        <td>운영시간</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1회차</td>
                        <td>09:30 ~</td>
                      </tr>
                      <tr>
                        <td>2회차</td>
                        <td>11:00 ~</td>
                      </tr>
                      <tr>
                        <td>3회차</td>
                        <td>13:30 ~</td>
                      </tr>
                      <tr>
                        <td>4회차</td>
                        <td>15:30 ~</td>
                      </tr>
                    </tbody>
                  </table>
                  <p className="tip">
                    기본 50분 프로그램으로 진행되나, 추가 교육이 필요한 경우
                    담당부서로 연락주세요.
                  </p>
                </dd>
              </dl>
              <dl>
                <dt>
                  <Tit size="s4-1">
                    <strong>예약문의</strong>
                  </Tit>
                </dt>
                <dd>
                  <ul className="blit-list">
                    <li>
                      예약 사전 취소 없이 회차 시작 후 10분 내 입장하지 않을 시,
                      <br className="pc-show" />
                      <span className="m-show-inline">&nbsp;</span>예약 내역
                      자동 취소 및 향후 체험관 프로그램 예약 관람 제한
                    </li>
                    <li>자체 해설 및 수업 불가</li>
                  </ul>
                </dd>
              </dl>
              <dl className="bbn">
                <dt>
                  <Tit size="s4-1">
                    <strong>기타 유의사항</strong>
                  </Tit>
                </dt>
                <dd>
                  <ul className="blit-list">
                    <li>
                      유니세프 한국위원회 내 45인승 버스 최대 2대 까지만 주차
                      가능(사전문의 필수)
                    </li>
                    <li>회차 시작 10분 내로 입장</li>
                  </ul>
                </dd>
              </dl>
            </DlContainer>
          </Container>
        </SectionHow>
        <SectionBn>
          <Container>
            {userStore.isLoggedIn() ? (
              <button type="button" className="bn" onClick={handleApplyClick}>
                <Image pcSrc={Bn} mobileSrc={BnM} />
              </button>
            ) : (
              <button
                type="button"
                onClick={() => {
                  popupStore.show(`로그인 후에 지원 가능합니다.`, {
                    onConfirm: () =>
                      navigate(
                        `/auth/login?redirectTo=/involve/child-participation/village`,
                      ),
                  });
                }}
              >
                <Image pcSrc={Bn} mobileSrc={BnM} />
              </button>
            )}
            {applyData.length !== 0 ? (
              <Box>
                <Tit size="s3" color="sky">
                  이미 신청하셨나요?
                </Tit>
                <Button
                  type="button"
                  onClick={() => {
                    open();
                    setPopupTitle(`유니세프 어린이 체험관 신청내역`);
                    setTypeApply(`apply`);
                  }}
                >
                  신청내역 확인
                </Button>
              </Box>
            ) : null}
          </Container>
        </SectionBn>
        <SectionHow>
          <Container>
            <DlContainer>
              <Tit size="s8" className="mt-47">
                개인 자유관람
              </Tit>
              <dl>
                <dt>
                  <Tit size="s4-1">
                    <strong>신청 대상</strong>
                  </Tit>
                </dt>
                <dd>
                  <p>전 연령</p>
                </dd>
              </dl>
              <dl>
                <dt>
                  <Tit size="s4-1">
                    <strong>관람안내</strong>
                  </Tit>
                </dt>
                <dd>
                  <p>
                    별도의 도슨트 프로그램은 제공되지 않으며 자유롭게 관람하실
                    수 있습니다.
                  </p>
                </dd>
              </dl>
              <dl>
                <dt>
                  <Tit size="s4-1">
                    <strong>운영 시간</strong>
                  </Tit>
                </dt>
                <dd>
                  <ul className="blit-list">
                    <li>
                      상시 (휴무: 주말, 공휴일, 창립기념일(1/2) 및 기타
                      기관에서 지정한 휴무일)
                    </li>
                    <li>09:00 ~ 17:00</li>
                    ※단체 프로그램이 있는 경우
                    대기가 발생할 수 있습니다.
                  </ul>
                </dd>
              </dl>
              <dl>
                <dt>
                  <Tit size="s4-1">
                    <strong>예약문의</strong>
                  </Tit>
                </dt>
                <dd>
                  <ul>
                    <li>현장접수를 통해 관람하실 수 있습니다.</li>
                  </ul>
                </dd>
              </dl>
              <dl className="pb-92 bbn">
                <dt>
                  <Tit size="s4-1">
                    <strong>기타 유의사항</strong>
                  </Tit>
                </dt>
                <dd>
                  <ul>
                    <li>
                      유니세프 한국위원회 내 주차장이 매우 협소하므로,
                      대중교통을 이용해 주시길 바랍니다.
                    </li>
                  </ul>
                </dd>
              </dl>
            </DlContainer>
          </Container>
        </SectionHow>
      </SectionBgc>
      <SectionCourtesy className="by-sub-main-layout">
        <Container>
          <div>
            <Image pcSrc={CourtesyImg1} mobileSrc={CourtesyImg1M} />
            <CourtesyText>
              <Tit size="s4" className="blue">
                체험관 안에서는 조용히 말해요.
              </Tit>
              <p>
                '유니세프 어린이체험관'은 여러 사람들이 함께 체험하는 공간으로
                모두가 즐거운 체험을 할 수 있도록 조용히 말해요.
              </p>
            </CourtesyText>
          </div>
          <div>
            <Image pcSrc={CourtesyImg2} mobileSrc={CourtesyImg2M} />
            <CourtesyText>
              <Tit size="s4" className="blue">
                음식물과 음료는 먹을 수 없어요.
              </Tit>
              <p>
                쾌적한 체험관을 위해 음식물이나 음료를 가지고 들어올 수 없어요.
              </p>
            </CourtesyText>
          </div>
          <div>
            <Image pcSrc={CourtesyImg3} mobileSrc={CourtesyImg3M} />
            <CourtesyText>
              <Tit size="s4" className="blue">
                질서를 지키고 안전하게 이용해요.
              </Tit>
              <p>
                모두가 체험에 집중할 수 있도록 차례를 지켜 체험하고 체험을 마친
                후에는 반드시 정리해 주세요. 장난치거나 낙서하지 않기로
                약속해요.
              </p>
            </CourtesyText>
          </div>
          <div>
            <Image pcSrc={CourtesyImg4} mobileSrc={CourtesyImg4M} />
            <CourtesyText>
              <Tit size="s4" className="blue">
                어린이는 보호자와 함께 다녀요.
              </Tit>
              <p>
                7세 미만 어린이는 반드시 보호자와 동반해야 입장이 가능해요.
                어린이들이 안전하고 재미있게 체험할 수 있도록 보호자분들이
                함께해 주세요.
              </p>
            </CourtesyText>
          </div>
        </Container>
      </SectionCourtesy>
      <SectionContact className="by-sub-main-layout">
        <Container>
          <ContactUs itemData={contactData} />
        </Container>
      </SectionContact>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
            </div>
          </SectionHeader>
          <ThumbnailCardSample newsLists={newsLists} />
          {hasMore && (
            <ButtonContainer>
              <Button
                outline
                width={352}
                onClick={() => {
                  loadNextPage();
                }}
              >
                <span className="ico">더 보기</span>
              </Button>
            </ButtonContainer>
          )}
        </Container>
      </SectionNews>
      <VillageLayerPopup
        isOpen={popupOpen}
        PopTitle={PopupTitle}
        onRequestClose={close}
        typeApply={typeApply}
        applyData={applyData}
      />
    </LayoutWithTitle>
  );
};
export default Village;
